<template>
  <embedded :widget="widget"></embedded>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Embedded from '../components/Embedded.vue'

export default {
  name: 'Posts',
  components: {
    Embedded
  },
  data () {
    return {
      changes: false,
      selectedLayout: null,
      selectedProperties: []
    }
  },
  async mounted () {
    this.fetchWidget(this.$route.params.widget_id)

    const response = await this.fetchWidget(this.$route.params.widget_id)
    if (response) {
      this.selectedProperties = this.widget.properties || []
      this.selectedLayout = this.widget.layout
    }
  },
  methods: {
    ...mapActions(['fetchWidget', 'updateWidget']),
    handleChange () {
      this.changes = true
    },
    handleChangeLayout (layout) {
      console.log(layout)
      this.widget.layout = null
      setTimeout(function () { this.widget.layout = layout }.bind(this), 10)
      this.handleChange()
      return false
    },
    async handleUpdate (widget) {
      const data = {
        name: widget.name,
        layout: widget.layout,
        propertyLogo: widget.propertyLogo,
        propertyName: widget.propertyName,
        postTitle: widget.postTitle,
        postCaption: widget.postCaption,
        properties: widget.properties
      }
      const payload = {
        data: data,
        documentId: widget.$id,
        read: widget.$read,
        write: widget.$write
      }

      const response = await this.updateWidget(payload)

      if (response) {
        this.changes = false
      }
    }
  },
  watch: {
    selectedProperties () {
      this.widget.properties = this.selectedProperties
    }
  },
  computed: {
    ...mapGetters(['widget', 'properties'])
  }
}
</script>
